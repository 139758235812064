/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useRef } from 'react';
import { useFrame } from '@react-three/fiber'; // Corrected import
import { useGLTF, useAnimations, Bounds } from '@react-three/drei';


export function Model(props) {
  const group = useRef(); 
  const { nodes, materials, animations } = useGLTF('/planets/Planet1.glb');
  const { actions } = useAnimations(animations, group);


  useFrame(() => {
    if (group.current) {
        group.current.rotation.y += 0.01; // Adjust rotation speed here
    }
  });
  
  return (
    <group ref={group} {...props} dispose={null}>
   
    <group position={[-0.003, 0.039, -0.031]} scale={0.08}>
      <group rotation={[Math.PI, -0.175, Math.PI]}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Planet_43.geometry}
        material={materials.Material}
      />
    </group>
    </group>
  </group>
  )
}

useGLTF.preload('/planets/Planet1.glb')