import React, { useRef } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import { Suspense } from 'react';
import './App.css'; 
import { Model } from './Model';

export default function App() {
  return (
<div style={{ height: '100vh', 
              width: '100vw', 
              //backgroundImage: 'url("/background.jpeg")', 
              //backgroundSize:'cover', 
              background: "transparent",
              //backgroundColor:"rgba(55,55,55,0.8)",
              overflow: 'hidden' }}>
 
 <Canvas shadows camera={{ position: [1, 2, 14], fov: 10 }}>
        <ambientLight intensity={1} />
        <directionalLight position={[0, 1, 15]} intensity={2} />
        <Suspense fallback={null}>
        <OrbitControls enableZoom={false} enablePan={false} enableRotate={true} />
          <Model />
        </Suspense>
        
      </Canvas>
    </div>
  );
}

